import React from 'react';

const SvgCheckedLabel = ({title, checked}) => (
  <label className="flex">
    <span className="flex-none bg-yellow-300 text-blue-800 w-4 h-4 mr-2 mt-1 rounded-full inline-flex items-center justify-center">
      {checked ?  
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
          <path d="M20 6L9 17l-5-5"></path>
        </svg>
        : null
      }
    </span>
    <span>{title}</span>
  </label>
);

export default SvgCheckedLabel;
