import React from 'react';
// import {StaticImage} from "gatsby-plugin-image"
import Divider from './Divider';
import SvgCheckedLabel from './SvgCheckedLabel';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const PricingTable = () => {
  const {t, i18n} = useTranslation();
  return(
    <section className="text-gray-600 body-font py-10 overflow-hidden">
      <div className="my-cont">
        <Divider title={t('table.title')} />
        <div className="text-center mb-10">
          <p className="leading-relaxed lg:w-3/5 mx-auto text-xl text-blue-900">{t('table.tag')}</p>
        </div>
        <div className="flex flex-wrap -m-4">
          <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
            <div className="h-full p-6 rounded-lg border-2 border-yellow-300 flex flex-col relative overflow-hidden">
              <h2 className="sm-title pb-5">{t('plan1.title')}</h2>
              <h1 className={"text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-yellow-300" + (i18n.language === 'vi' ? " flex-col" : '')}>
                <span className="text-blue-900">{t('plan1.price')}</span>
                <span className="text-sm ml-1 mt-5 font-normal text-gray-500">{t('plan1.per')}</span>
              </h1>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan1.val1')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan1.val2')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan1.val3')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-8">
                <SvgCheckedLabel title={t('plan1.val4')} checked={true} />
              </p>
              <a className="flex items-center mt-auto text-white bg-blue-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-blue-700 rounded" 
                href={`https://app.iohub.tech/subscription?lng=${i18n.language.toLowerCase().substring(0, 2)}`} >
                {t('table.freetrial')}
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
            <div className="h-full p-6 rounded-lg border-2 border-yellow-500 flex flex-col relative overflow-hidden">
              <span className="bg-yellow-500 text-white px-3 py-1 tracking-widest text-xs absolute right-1 top-1 rounded">POPULAR</span>
              <h2 className="sm-title pb-5">{t('plan2.title')}</h2>
              <h1 className={"text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-yellow-300" + (i18n.language === 'vi' ? " flex-col" : '')}>
                <span className="text-blue-900">{t('plan2.price')}</span>
                <span className="text-sm ml-1 mt-5 font-normal text-gray-500">{t('plan2.per')}</span>
              </h1>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan2.val1')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan2.val2')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan2.val3')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-8">
                <SvgCheckedLabel title={t('plan2.val4')} checked={true} />
              </p>
              <a className="flex items-center mt-auto text-blue-900 hover:text-white bg-yellow-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-blue-700 rounded" 
                href={`https://app.iohub.tech/subscription/purchase/professional/vnd?lng=${i18n.language.toLowerCase().substring(0, 2)}`} >
                {t('table.signup')}
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
            <div className="h-full p-6 rounded-lg border-2 border-yellow-300 flex flex-col relative overflow-hidden">
              <h2 className="sm-title pb-5">{t('plan3.title')}</h2>
              <h1 className={"text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-yellow-300" + (i18n.language === 'vi' ? " flex-col" : '')}>
                <span className="text-blue-900">{t('plan3.price')}</span>
                <span className="text-sm ml-1 mt-5 font-normal text-gray-500">{t('plan3.per')}</span>
              </h1>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan3.val1')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan3.val2')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan3.val3')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan3.val4')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan3.val5')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-8">
                <SvgCheckedLabel title={t('plan3.val6')} checked={true} />
              </p>
              <a className="flex items-center mt-auto text-blue-900 hover:text-white bg-yellow-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-blue-700 rounded" 
                href={`https://app.iohub.tech/subscription/purchase/premium/vnd?lng=${i18n.language.toLowerCase().substring(0, 2)}`} >
                {t('table.signup')}
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
            <div className="h-full p-6 rounded-lg border-2 border-yellow-300 flex flex-col relative overflow-hidden">
              <h2 className="sm-title pb-5">{t('plan4.title')}</h2>
              <h1 className={"text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-yellow-300" + (i18n.language === 'vi' ? " flex-col" : '')}>
                <span className="text-blue-900">{t('plan4.price')}</span>
                <span className="text-sm ml-1 mt-5 font-normal text-gray-500">{t('plan4.per')}</span>
              </h1>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan4.val1')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan4.val2')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan4.val3')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan4.val4')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan4.val5')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <SvgCheckedLabel title={t('plan4.val6')} checked={true} />
              </p>
              <p className="flex items-center text-gray-600 mb-8">
                <SvgCheckedLabel title={t('plan4.val7')} checked={true} />
              </p>
              <a className="flex items-center mt-auto text-blue-900 hover:text-white bg-yellow-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-blue-700 rounded" 
                href={`https://app.iohub.tech/subscription/purchase/enterprise/vnd?lng=${i18n.language.toLowerCase().substring(0, 2)}`} >
                {t('table.signup')}
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="flex w-full my-5">
          <p className="text-sm tracking-wide">{t('table.freetrialstep')}</p>
        </div>
      </div>
    </section>
  );
};

export default PricingTable;
